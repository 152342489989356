import Axios from 'axios';
import { useEffect, useState } from 'react';
import './App.css';



function App() {
  const[obsStartStop, setObsStart] = useState("");
  //const[obsStop, setObsStop] = useState("");
  const[obsStatus, setObsStatus] = useState("");
  //const[rdpStatus, setRdpStatus] = useState("");
  const[obsStatusColor, setObsStatusColor] = useState(false);
  const[rdpStatusColor, setRdpStatusColor] = useState(false);

  useEffect(() =>{}, []);
  Axios.get("https://jumpserver.thefatherscall.org/api/obs_status/").then((res) => { setObsStatus(res.data); if (res.data.OBS != "running") {setObsStatusColor(true); } else{setObsStatusColor(false)};if (res.data.RDP != "UP") {setRdpStatusColor(true); } else{setRdpStatusColor(false)};});
  Axios.get("https://jumpserver.thefatherscall.org/api/start_stop/").then((res) => { setObsStart(res.data);  }); //.then((res) => {if (res.data.OBS != "running") {setObsStatusColor(true); } else{setObsStatusColor(false)};});
 
  return (
    <div className="App">
      <header className="App-header">
        <button>This is a button</button>
        <p>
          
          OBS <span style={{ color:obsStatusColor ? 'red' : 'green' }}>{obsStatus.OBS}</span> <br></br>
          RDP <span style={{ color:rdpStatusColor ? 'red' : 'green' }}> {obsStatus.RDP}</span> <br></br>
          OBS will Start at {obsStartStop.start}<br></br>
          OBS will Stop at {obsStartStop.stop}
        </p>
        
      </header>
    </div>
  );
}

export default App;
